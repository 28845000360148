import { getMapsHelper } from './util/mapsHelper';
import { AutomatitCarousel } from './automatit_carousel.m';

let mapInitialized = false;

export const initFacilityMobile = () => {
	document.querySelector('#toggle_fac_info').addEventListener('click', () => {
		document.querySelector('#toggle_fac_info').switchAttribute('state', 'open', 'closed');
		document.querySelector('#facility_top').switchAttribute('state', 'open', 'closed');
	});
};

export const initFacilityCaro = () => {
	AutomatitCarousel({
		element: document.querySelector('#facility_caro'),
		imagesAsBackgrounds: true,
		images: window.facImgs,
		showDots: true,
		useChevrons: false
	});
};

function startFacMap(){
	const mapHelper = getMapsHelper();

	mapHelper.ready()
		.then(() => {
			const theMap = mapHelper.createMap({
				element: document.querySelector('#facility_map'),
				locationElementSelector: '#facility_map',
				useRichmarker: true,
				markerReducer: el => {
					return {
						lat: el.getAttribute('lat'),
						lng: el.getAttribute('lng'),
						content: `<a ${(el.hasAttribute('blank'))?'target="_blank"':''} href="${el.getAttribute('link')}" class="map_pin"><img src="/static/images/icons/ic_red_solid_map_pin.svg" alt="" /></a>`
					};
				}
			});

			// eslint-disable-next-line no-undef
			google.maps.event.addListenerOnce(theMap, 'idle', () => {
				theMap.setZoom(15);
			});
		});		
}
	
export const initFacilityMap = () => {
	const mapElement = document.querySelector('#facility_map');
	const toggleButtons = document.querySelectorAll('#facility_caro_map_btns button');
	document.querySelector('#facility_caro_map_btns').addEventListener('click', e => {
		const clickedButton = e.target.closest('button');

		if(clickedButton) {
			if(clickedButton.getAttribute('tab') === 'map') {
				if(!mapInitialized){
					mapInitialized = true;
					startFacMap();
				}
				if(!mapElement.hasAttribute('show')) {
					mapElement.setAttribute('show', true);
				}
			} else {
				mapElement.removeAttribute('show');
			}

			toggleButtons.forEach(i => {
				i.removeAttribute('active');
				if(i.getAttribute('tab') === clickedButton.getAttribute('tab')) {
					i.setAttribute('active', true);
				}
			});
		}
	});
};

export const initFacilityFilters = () => {
	const filters = document.querySelectorAll('#facility_filters button');
	const rows = document.querySelectorAll('.rates_row');
	const noUnits = document.querySelector('#no_units_found');
	document.querySelector('#facility_filters').addEventListener('click', e => {
		const clickedFilter = e.target.closest('button');

		noUnits.removeAttribute('show');

		if(clickedFilter) {
			filters.forEach(i => {
				i.removeAttribute('active');
			});
			clickedFilter.setAttribute('active', true);
		}

		const activeFilter = document.querySelector('#facility_filters button[active]');

		let numUnits = 0;

		rows.forEach(i => {
			i.setAttribute('hide', true);
			if(activeFilter.dataset.size === i.dataset.size || activeFilter.dataset.size === "all") {
				i.removeAttribute('hide');
				numUnits++;
			}
		});

		if(!numUnits) {
			noUnits.setAttribute('show', true);
		}
	});
};

export const initFacilityJumpLinks = () => {
	document.querySelector('#facility_jump_links').addEventListener('click', e => {
		const clickedButton = e.target.closest('a');

		if(clickedButton) {
			e.preventDefault();
			// eslint-disable-next-line no-undef
			Velocity(document.querySelector(clickedButton.getAttribute('href')), 'scroll');
		}
	});
};
