/* eslint-disable no-undef */
import { getMapsHelper } from './util/mapsHelper';

let initialized = false;

function inView(el){
	const rect = el.getBoundingClientRect();
	return (rect.top >= 0 && rect.top <= (window.innerHeight || document.documentElement.clientHeight));
}

function startMap(){
	const mapHelper = getMapsHelper();

	mapHelper.ready()
		.then(() => {
			const theMap = mapHelper.createMap({
				element: document.querySelector('#multi_map'),
				locationElementSelector: '.data_source',
				useRichmarker: true,
				markerReducer: el => {
					return {
						lat: el.getAttribute('lat'),
						lng: el.getAttribute('lng'),
						content: `
						<a class="map_pin" href="${el.getAttribute('link')}">
							<img src="/static/images/icons/ic_red_solid_map_pin.svg" alt="Visit facility" />
							<span>${el.getAttribute('count')}</span>
						</a>
						`
					};
				},
				mouseIn: (el) => {
					el.classList.add('hover');
				},
			});

			google.maps.event.addListenerOnce(theMap, 'idle', () => {

				document.querySelectorAll('.data_source').forEach(i => {
					const markerData = mapHelper.getMarker(i);
					markerData.element.addEventListener('mouseenter', () => {
						markerData.element.classList.add('hover');
						i.classList.add('hover');
					});
					markerData.element.addEventListener('mouseleave', () => {
						markerData.element.classList.remove('hover');
						i.classList.remove('hover');
					});
				});
			});
		});
}

export const initMultiMap = () => {
	let map = document.querySelector('#multi_map');
	if(inView(map)){
		startMap();
	}else{
		window.onscroll = function(){
			const rect = map.getBoundingClientRect();
			const isInView = (rect.top >= 0 && rect.top <= ((window.innerHeight || document.documentElement.clientHeight) + 500));
			if(!initialized && isInView){
				startMap();
				initialized = true;
			}
		};
	}
};