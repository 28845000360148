if (!Element.prototype.matches) {
	Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector;
}
if (!Element.prototype.closest) {
	Element.prototype.closest = function(s) {
		var el = this;
		do {
			if (el.matches(s)) return el;
			el = el.parentElement || el.parentNode;
		} while (el !== null && el.nodeType === 1);
		return null;
	};
}

import { initMobileNav, initDoubleClick } from './header';
import { initFacilityMobile, initFacilityCaro, initFacilityMap, initFacilityFilters, initFacilityJumpLinks } from './facility';
import { contactSubmit } from './contact.js';
import { initReviewsCaro } from './home';
import { initMultiMap } from './multiMap';

Element.prototype.switchAttribute = function(attribute, val1, val2) {
	this.setAttribute(attribute, (this.getAttribute(attribute) === val1 ? val2 : val1));
};

initMobileNav();
initDoubleClick();

if(document.querySelector('#toggle_fac_info')) {
	initFacilityMobile();
}

if(document.querySelector('#facility_caro')) {
	initFacilityCaro();
}

if(document.querySelector('#facility_map')) {
	initFacilityMap();
}

if(document.querySelector('#facility_jump_links')) {
	initFacilityJumpLinks();
}

if(document.querySelector('#facility_filters')) {
	initFacilityFilters();
}

if (document.getElementById('contact_form')) {
	contactSubmit({
		required: ['name', 'email', 'facility', 'message'],
		elementSelector: '#contact_form'
	});
}
// Reviews Caro
if(document.querySelector('#reviews_caro')) {
	initReviewsCaro();
}
// Multi Map
if(document.querySelector('#multi_map')) {
	initMultiMap();
}