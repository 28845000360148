import { AutomatitCarousel } from './automatit_carousel.m';

export const initReviewsCaro = () => {
	window.reviewsCaro = AutomatitCarousel({
		element: document.querySelector('#reviews_caro'),
		intervalTiming: false,
	});
	window.mobileReviewsCaro = AutomatitCarousel({
		element: document.querySelector('#reviews_caro_mobile'),
		intervalTiming: false,
	});
	const helpItems = document.querySelectorAll('.reviews_item');
	document.getElementById('about_wrap').addEventListener('click', function(e) {
		if(e.target && e.target.matches('button.review_read_more')) {
			let el = e.target.parentElement.parentElement;
			if(el.classList.contains('active')) {
				el.classList.remove('active');
			}
			else {
				helpItems.forEach(i => {
					i.classList.remove('active');
				});
				el.classList.add('active');
			}
		}
		if(e.target.matches('div.review_close')){
			e.target.parentElement.parentElement.parentElement.classList.remove('active');
		}
	});
};
